import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Navbar from "../components/Navbar"
import hausgruber from "../images/bg/hausgruber.webp"

const HeadWrap = styled.section`
  background-position: center;
  @media (max-width: 575.98px) {
    background-position: top;
    height: auto!important;
    max-height: none!important;
    overflow: auto!important;
  }
`;


function Header({ height }) {
  const pageStyle = {
    backgroundImage:
      `url(${hausgruber})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backgroundBlendMode: "multiply",
    maxHeight: `${height}px`,
    height: `${height}px`,
    overflow: "hidden"
  }

  return (
    <HeadWrap id="home" style={pageStyle} className = "container-fluid">
      <div className="container">
        <Navbar />
      </div>
    </HeadWrap>
  )
}

Header.defaultProps = {
  height: 400,
}

Header.propTypes = {
  height: PropTypes.number,
}

export default Header
