import React from "react"
import "../global.css"
import KontaktAnfahrt from "../subpages/Kontakt";
import End from "../subpages/End";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SeoHelmet from "../components/seo"

function Kontakt() {
  return (
    <div>
      <SeoHelmet 
        title="Kontakt - Haus Gruber Ferienwohnungen"
        description="Hier finden Sie unsere Kontaktdaten und den Routenplaner."
      />
      <Header />
      <KontaktAnfahrt />
      <End/>
      <Footer/>
    </div>
  )
}

export default Kontakt
