import React from "react"
import Fade from 'react-reveal/Fade';

function KontaktAnfahrt() {
  return (
    <div id="kontakt-anfahrt" className="container-fluid mb-5">
      <div className="container">
        <Fade>
          <div className="flexbox">
              <h1 className="text-center my-5">Kontakt</h1>
              <address>
                <strong>Ferienwohnungen Haus Gruber</strong>
                <br />
                Weng 80<br />
                5453 Werfenweng<br /><br />
                Telefon: <a href="tel:+436466430">+43 6466 430</a><br />
                Mobil: <a href="tel:+436606350509">+43 660 6350509</a><br /><br />
                E-Mail: <a href="mailto:info@hausgruber-werfenweng.at">info@hausgruber-werfenweng.at</a>
              </address>
          </div>
          <hr />
          <div className="flexbox">
              <h1 className="text-center my-5">Anreise</h1>
              <p>So finden Sie zu uns nach Werfenweng im Salzburger Land/Österreich:</p>
              <p className="mb-4"><strong>Mit der Bahn:</strong><br />Reisen Sie entspannt und nervensparend mit der Bahn aus ganz Europa nach Werfenweng. Selbstverständlich holen wir Sie vom Bahnhof ab!<br /><br/>
                Die Mobilitätszentrale Pongau GmbH erstellt Ihnen kostenlos Ihren individuellen Reiseplan und besorgt Ihnen das Fahrticket.<br/>
                Weitere Infos unter: <a href="https://www.werfenweng.eu/service/anreise/mit-der-bahn/" target="_blank" rel="noreferrer nofollow">Wundermittel Werfenweng – Sanfte Mobilität</a></p>
              <p className="mb-4"><strong>Mit dem Auto: </strong>
                (<a href="https://www.google.de/maps/dir//Weng+80,+Weng/@47.4668166,13.2274046,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4776daab0d979619:0x2c288a00e3f4273b!2m2!1d13.2624237!2d47.4668219" target="_blank" rel="noreferrer nofollow">zum Routenplaner</a>)
                <br />Werfenweng liegt etwa 45 Kilometer südlich von Salzburg und ist einfach via Autobahn A10 (E55 /E66) erreichbar.<br /><br/>
                Von Salzburg / München kommend:<br/>
                über die Tauernautobahn (A10) Abfahrt Werfen, durch Imlau nach Werfenweng (6 Kilometer)<br/><br/>
                Von Villach kommend:<br/>
                über die Tauernautobahn (A10) Abfahrt Pfarrwerfen / Werfen, nach Werfenweng (4 Kilometer)
              </p>
              <p className="mb-4"><strong>Mit dem Flugzeug:</strong><br />Der Flughafen “W.A. Mozart” liegt etwa 50 km entfernt von Werfenweng bei Salzburg Stadt.</p>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default KontaktAnfahrt
